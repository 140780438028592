import React from "react"
import { graphql } from "gatsby"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import Carousel, { CarouselImages } from "Components/Carousel"
import SEO from "Components/core/SEO"

interface DcuCampusTourProps {
  data: CarouselImages
}

const DcuCampusTour: React.FC<DcuCampusTourProps & GatsbyProps> = ({
  location,
  data: { smallestImages, smallImages, mediumImages, largeImages },
}) => (
  <StandardLayout location={location}>
    <SEO title="DCU Campus Tour" />
    <h1>DCU Campus Tour</h1>
    <p>
      My first flash application created in DCU was created as part of a 2 man
      team and became an app that showcased DCU as well as some of the staff and
      students related to the MMA course at the time.
    </p>
    <Carousel
      smallestImages={smallestImages}
      smallImages={smallImages}
      mediumImages={mediumImages}
      largeImages={largeImages}
      altTexts={[
        "Screenshot of home",
        "Screenshot of buildings selection page",
        "Screenshot of building page",
      ]}
    />
  </StandardLayout>
)
export const query = graphql`
  query {
    smallestImages: allFile(
      filter: { relativePath: { glob: "flash-projects/dcu-buildings*" } }
    ) {
      edges {
        node {
          ...CarouselSmallestImage
        }
      }
    }
    smallImages: allFile(
      filter: { relativePath: { glob: "flash-projects/dcu-buildings*" } }
    ) {
      edges {
        node {
          ...CarouselSmallImage
        }
      }
    }
    mediumImages: allFile(
      filter: { relativePath: { glob: "flash-projects/dcu-buildings*" } }
    ) {
      edges {
        node {
          ...CarouselMediumImage
        }
      }
    }
    largeImages: allFile(
      filter: { relativePath: { glob: "flash-projects/dcu-buildings*" } }
    ) {
      edges {
        node {
          ...CarouselLargeImage
        }
      }
    }
  }
`
export default DcuCampusTour
